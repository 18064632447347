import { CreateRide } from 'pages/CreateRide';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import Profile from 'pages/Profile';
import { AccountSettings } from 'pages/AccountSettings';
import Magic from 'pages/Magic';
import RideDetails from 'pages/RideDetails';
import RidersPage from 'pages/RidersPage';
import { RiderProfile } from 'pages/RiderProfile';
import CreateRider from 'pages/CreateRider';
import { Router, Route, Switch } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import { PublicOnly } from './PublicOnly';
import RideHistory from 'pages/RideHistory';
import EditRide from 'pages/EditRide';
import EditRideDetails from 'pages/EditRideDetails';
import Welcome from 'pages/Welcome';
import Start from 'pages/Start';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';
import history from './history';
import { useAuth } from '../contexts/auth-context';
import React from 'react';
import { useApolloClient } from '@apollo/client';
import Launcher from 'pages/Launcher';
import LaunchSession from 'pages/LaunchSession';
import LaunchFhir from 'pages/LaunchFhir';
import ChooseRider from 'pages/ChooseRider';
import { AccountPage } from '../pages/AccountPage';
import { SelectRider } from '../pages/SelectRider';
import { FeedbackPage } from '../pages/FeedbackPage';
import { StripeAddPage } from '../pages/StripeAddPage';
import GraphiQLPage from 'pages/GraphiQLPage';

export default function RootRouter() {
  const auth = useAuth();
  const apolloClient = useApolloClient();

  //@ts-ignore
  !auth.isLoggedIn && zE('messenger:set', 'cookies', false);

  React.useEffect(() => {
    // using global events that dispatched from RN rider app
    // watch webview element inside the rider app
    const listener = (event: any) => {
      if (event?.detail?.eventName === 'QUIT') {
        auth.logout(apolloClient);
      }

      if (event?.detail?.eventName === 'REDIRECT') {
        const data = event?.detail?.data;
        history.push(`/rides/${data}?openReview=true`);
      }
    };

    const elem = document?.getElementById('root');

    if (elem) {
      elem.addEventListener('eventFromRN', listener);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('eventFromRN', listener);
      }
    };
  });

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (
            <PublicOnly>
              <Login />
            </PublicOnly>
          )}
        />
        <Route
          exact
          path="/driverApp/rideDetails"
          children={() => {
            const search = window.location.search;
            window.location.replace('onwarddriver://rideDetails' + search);
            return null;
          }}
        />
        <Route
          exact
          path="/driverApp/settings/payment"
          children={() => {
            window.location.replace('onwarddriver://settings/payment');
            return null;
          }}
        />
        <Route
          exact
          path="/welcome"
          render={() => (
            <PublicOnly>
              <Welcome />
            </PublicOnly>
          )}
        />
        <Route
          exact
          path="/start"
          render={() => (
            <PublicOnly>
              <Start />
            </PublicOnly>
          )}
        />
        <Route
          exact
          path="/resetPassword"
          render={() => (
            <PublicOnly>
              <ForgotPassword />
            </PublicOnly>
          )}
        />
        <Route exact path="/updatePassword">
          <UpdatePassword />
        </Route>
        <Route
          exact
          path="/"
          render={() => (
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/rides/:slug/paymentMethods/new"
          children={<StripeAddPage />}
        />
        <Route
          exact
          path="/rides/new"
          render={() => (
            <RequireAuth>
              <CreateRide />
            </RequireAuth>
          )}
        />
        <Route
          path="/rides/new/:riderId"
          render={() => (
            <RequireAuth>
              <CreateRide />
            </RequireAuth>
          )}
        />
        <Route
          path="/rides/:rideId/copy"
          render={() => (
            <RequireAuth>
              <CreateRide />
            </RequireAuth>
          )}
        />
        <Route
          path="/rides/:rideId/edit"
          render={() => (
            <RequireAuth>
              <EditRide />
            </RequireAuth>
          )}
        />
        <Route
          path="/rides/:rideId/editDetails"
          render={() => (
            <RequireAuth>
              <EditRideDetails />
            </RequireAuth>
          )}
        />
        <Route
          path="/rides/:rideId"
          render={() => (
            <RequireAuth>
              <RideDetails />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/riders"
          render={() => (
            <RequireAuth>
              <RidersPage />
            </RequireAuth>
          )}
        />
        <Route
          path="/riders/new"
          render={() => (
            <RequireAuth>
              <CreateRider />
            </RequireAuth>
          )}
        />
        <Route
          path="/riders/select"
          render={() => (
            <RequireAuth>
              <SelectRider />
            </RequireAuth>
          )}
        />
        <Route
          path="/riders/:riderId"
          render={() => (
            <RequireAuth>
              <RiderProfile />
            </RequireAuth>
          )}
        />
        <Route
          path="/rideHistory"
          render={() => (
            <RequireAuth>
              <RideHistory />
            </RequireAuth>
          )}
        />

        <Route
          exact
          path="/profile"
          render={() => (
            <RequireAuth>
              <Profile />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/account"
          render={() => (
            <RequireAuth>
              <AccountSettings />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/magic"
          render={() => (
            <RequireAuth>
              <Magic />
            </RequireAuth>
          )}
        />

        <Route exact path="/launch" children={<Launcher />} />
        <Route exact path="/launch/session" children={<LaunchSession />} />
        <Route
          exact
          path="/launch/fhir"
          render={() => (
            <RequireAuth>
              <LaunchFhir />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/launch/rider"
          render={() => (
            <RequireAuth>
              <ChooseRider />
            </RequireAuth>
          )}
        />
        <Route exact path="/signup" children={<AccountPage />} />
        <Route exact path="/feedback/:slug" children={<FeedbackPage />} />
        <Route
          exact
          path="/graphiql/custodian"
          render={() => (
            <RequireAuth>
              <GraphiQLPage userType="custodian" />
            </RequireAuth>
          )}
        />
        <Route
          exact
          path="/graphiql/driver"
          render={() => (
            <RequireAuth>
              <GraphiQLPage userType="driver" />
            </RequireAuth>
          )}
        />
      </Switch>
    </Router>
  );
}
