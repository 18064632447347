import React from 'react';
import { Box } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { makeStyles } from '@material-ui/core/styles';
import { PaymentMethod } from 'generated/graphql';

const useStyles = makeStyles(theme => ({
  cardIcon: {
    marginRight: theme.spacing(1),
  },
}));

interface InvoiceRideProps {
  paymentMethodOptions?: PaymentMethod[];
  small?: boolean;
  paymentMethodId?: number;
}

const InvoiceRide: React.FC<InvoiceRideProps> = ({
  paymentMethodOptions,
  small,
  paymentMethodId
}) => {
  const classes = useStyles();

  let paymentMethodName = ""

  if(paymentMethodId) {
    const paymentMethod = paymentMethodOptions?.find(m => m.id === paymentMethodId);
    paymentMethodName = paymentMethod?.name;
  } else {
    paymentMethodName = paymentMethodOptions && paymentMethodOptions[0] && paymentMethodOptions[0].name || '';
  }

  return (
    <Box display="flex">
      <CreditCardIcon
        color="secondary"
        className={classes.cardIcon}
        fontSize={small ? 'small' : 'default'}
      />
      <Box
        flexGrow={1}
        fontSize={small ? 'caption.fontSize' : 'body1.fontSize'}
      >
        {paymentMethodName}
      </Box>
    </Box>
  );
};

export default InvoiceRide;
