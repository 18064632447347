import { useEffect, useState } from 'react';
import { CurrentPayerType, AccountChannel } from './types';

import { Account, Rider, FinancialResponsibilityChoice, PaymentMethod} from 'generated/graphql';

// contract between backend and front end
export const SPLIT_PAY_PAYER_TYPE = "Split: Private + Facility"; // Split Pay
export const PRIVATE_PAYER_TYPE = "Private"; // Private

export const usePaymentMethods = (
  account: Account | null | undefined,
  rider: Rider,
  selectedPayerType: string,
  financialResponsibilityChoice: FinancialResponsibilityChoice,
  forceRefreshTimestamp: Date
) => {
  // This custom hook implements logic for selecting payment options available on booking page depending on many factors and different states for a Rider and Account
  //
  // The logic on high level can be described as follows
  //
  //  | 1  | Channel  |     Payer Type       | accountFinancialResponsibilityChoices | requirePaymentFromPrivatePayerOnBooking | promptForPayerDemographics | displayPayerDemographicForm | displayPaymentMethodForm   |            currentPayer         |
  //  |    |          |                      |               present?                |                                         |                            |                             |                            |                                 |
  //  |    |          |     [API config]      |            [API config]                |                 [API config]             |            [Form]          |           [Form]            |          [Form]            | [Form payment method selection] |
  //  | -- | -------- | -------------------- |-------------------------------------- | --------------------------------------- | -------------------------- | --------------------------- | ------------------------   | ------------------------------- |
  //  | 1  |  B2B     |  Private / Split Pay |                 ✅                    |                     ✅                  |               ✅            |              🟥             |             ✅            |                Rider             |
  //  | 2  |  B2B     |  Private / Split Pay |                 ✅                    |                     🟥                  |               ✅            |              ✅             |             🟥            |                Rider             |
  //  | 3  |  B2B     |      Non-Private     |                 ✅                    |                     ✅                  |               N/A*          |              N/A*            |             ✅            |                Account            |
  //  | 4  |  B2B     |      Non-Private     |                 ✅                    |                     🟥                  |               N/A*          |              N/A*            |             ✅            |                Account            |
  //  | 6  |  B2B     |         None         |                 🟥                    |                     🟥                  |               🟥            |              🟥             |             🟥            |                  N/A**             |
  //  | 7  |  B2C     |         None         |                 🟥                    |                     🟥                  |               🟥            |              🟥             |             ✅            |                Account           |
  //  --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //
  // *  Because payer is Non-Private and thus no private payer payment settings are applicable there
  // ** Because neither the payer demographic form nor payment method form will be displayed, we don't need a payer to populate the form
  //

  const [displayPayerDemographicForm, setDisplayPayerDemographicForm] = useState(false);
  const [displayPaymentMethodForm, setDisplayPaymentMethodForm] = useState(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethod[]>([]);
  const [currentPayer, setCurrentPayer] = useState({});

  useEffect(() => {
    if (!account?.channel) return;
    if (!account?.paymentMethods && !rider?.paymentMethods) return;

    const { requirePaymentFromPrivatePayerOnBooking, promptForPayerDemographics } = financialResponsibilityChoice || {requirePaymentFromPrivatePayerOnBooking: false, promptForPayerDemographics: false};

    // Always show payment method form for B2C
    if(account.channel == AccountChannel.B2C) {
      const accountPaymentMethods = account?.paymentMethods || [];

      setDisplayPayerDemographicForm(false);
      setDisplayPaymentMethodForm(true);
      setAvailablePaymentMethods(accountPaymentMethods);
      setCurrentPayer({ id: account?.id, type: CurrentPayerType.Account });
    } else { // B2B
      if((selectedPayerType === SPLIT_PAY_PAYER_TYPE) || (selectedPayerType === PRIVATE_PAYER_TYPE)) {
        if(requirePaymentFromPrivatePayerOnBooking) {
          setDisplayPayerDemographicForm(false);
          setDisplayPaymentMethodForm(true);
          setAvailablePaymentMethods(rider.paymentMethods || []);
          setCurrentPayer({ id: rider?.id, type: CurrentPayerType.Rider });
        } else {
          setDisplayPayerDemographicForm(true);
          setDisplayPaymentMethodForm(false);
          setAvailablePaymentMethods([]); // because that means payment will be collected
          setCurrentPayer({ id: rider?.id, type: CurrentPayerType.Rider });
        }
      } else {
        const pmtMethods = account?.paymentMethods || []
        const accountPaymentMethods = account?.invoiced ? [{ id: 'invoice', name: 'Invoice this ride' }, ...pmtMethods] : account?.paymentMethods

        setDisplayPayerDemographicForm(false);
        setDisplayPaymentMethodForm(false);
        setAvailablePaymentMethods(accountPaymentMethods || []);
        setCurrentPayer({ id: account?.id, type: CurrentPayerType.Account });
      }
    }

  }, [account?.paymentMethods, financialResponsibilityChoice, rider?.paymentMethods, forceRefreshTimestamp, selectedPayerType]);

  return [displayPayerDemographicForm, displayPaymentMethodForm, availablePaymentMethods, currentPayer];
};
