// this is referring to payment methods rather then ride.payerType
// indicates whose payment methods are available for selection
export enum CurrentPayerType {
  Account = "Account",
  Rider = "Rider",
  AccountAndRider = "AccountAndRider", // to be used in future?
}

export interface CurrentPayer {
  id: string;
  type: CurrentPayerType;
}

export enum AccountChannel {
  B2B = "b2b",
  B2C = "b2c",
  B2B2C = "b2b2c"
}