import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InvoiceRide from './rideFormComponents/InvoiceRide';
import { Maybe, RideSplitPayerEstimate, PaymentMethod } from 'generated/graphql';

interface SplitPaymentSummaryProps {
  splitPayerEstimates?: Maybe<Array<RideSplitPayerEstimate>>;
  paymentMethodOptions?: PaymentMethod[];
  invoiced?: boolean;
  paymentMethodLink?: React.ReactNode;
  detailView?: boolean;
  paymentMethodId?: number;
}

const SplitPaymentSummary: React.FC<SplitPaymentSummaryProps> = ({
  splitPayerEstimates,
  paymentMethodOptions,
  invoiced,
  paymentMethodLink,
  detailView,
  paymentMethodId
}) => {
  if (splitPayerEstimates && splitPayerEstimates.length > 0) {
    const hasPrivateTypeWithAddtlCost = splitPayerEstimates.some(
      estimate =>
        estimate.payerType === 'Private' && estimate.addtlCostFormatted,
    );

    const mediaSizeForMainBox = detailView ? 1000 : 1370;
    const medaSizeForInnerBox = detailView ? 1000 : 1480;

    return (
      <>
        {splitPayerEstimates.map((estimate, index) => {
          const isFacilityType = estimate.payerType === 'Facility';

          return (
            <Box
              display="flex"
              key={index}
              maxWidth={500}
              sx={{
                flexDirection: detailView
                  ? { xs: 'column', md: 'row' }
                  : { xs: 'column', lg: 'row' },
                [`@media (max-width:${mediaSizeForMainBox}px)`]: {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                flexGrow={1}
                display="flex"
                sx={{
                  flexDirection: detailView
                    ? { xs: 'column', md: 'row' }
                    : { xs: 'column', lg: 'row' },
                  [`@media (max-width:${medaSizeForInnerBox}px)`]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box fontSize={14} paddingLeft={1}>
                  • {estimate.payerType === 'Private' ? 'Private:' : 'Facility:'}
                </Box>
                <Box marginLeft={1} alignItems={'start'}>
                  {isFacilityType ? (
                    invoiced ? (
                      <InvoiceRide
                        paymentMethodOptions={[{ id: 'invoice', name: 'Invoice this ride' }]}
                        small
                      />
                    ) : (
                      <></>
                    )
                  ) : paymentMethodLink ? (
                    paymentMethodLink
                  ) : (
                    <InvoiceRide
                      paymentMethodOptions={paymentMethodOptions}
                      paymentMethodId={paymentMethodId}
                      small
                    />
                  )}
                </Box>
              </Box>
              <Box fontSize={14} textAlign={'right'}>
                {estimate.payerType === 'Private' ? (
                  estimate.addtlCostFormatted ? (
                    `${estimate.baseCostFormatted} + ${estimate.addtlCostFormatted} (base + addl.)`
                  ) : (
                    estimate.baseCostFormatted
                  )
                ) : (
                  <>
                    {hasPrivateTypeWithAddtlCost
                      ? `${estimate.baseCostFormatted} (covered)`
                      : estimate.baseCostFormatted}
                  </>
                )}
              </Box>
            </Box>
          )})}
      </>
    );
  } else {
    return null;
  }
};

export default SplitPaymentSummary;
